<template>
  <section class="space"></section>
</template>
<script>
export default {
  name: "HeaderSpace",
};
</script>
<style scoped>
.space {
  width: 100%;
  height: 70px;
}
</style>
